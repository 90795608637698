import { buildTypeObject, buildTypeVariants, pxToVW } from '../utils'

const styles = {
  serif: {
    font: `'SaolDisplay-Regular', serif`,
    weight: 400,
  },
  serifText: {
    font: `'SaolText-Regular', serif`,
    weight: 400,
  },
  bold: {
    font: `'Danzza-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    weight: 700,
  },
}

const sizes = {
  body: ['13px', '16px', '0px'],
  bodyLarge: ['28px', '32px', '0px'],
  bodyLarge_m: ['22px', '25px', '0px'],
  bodyLarge_s: ['18px', '20px', '0px'],
  h1_l: ['130px', '115px', '-1.5px'],
  h1: [pxToVW(112, 1440), pxToVW(100, 1440), pxToVW(-1, 1440)],
  h1_m: ['72px', '68px', '-0.5px'],
  h1_s: [pxToVW(45, 375), pxToVW(44, 375), pxToVW(-1, 1024)],
  h2: ['62px', '56px', '-0.5px'],
  h2_m: ['42px', '40px', '-0.5px'],
  h2_s: [pxToVW(36, 375), pxToVW(36, 375), pxToVW(-1, 1024)],
  h3: ['48px', '48px', '0px'],
  h3_m: ['36px', '36px', '0px'],
  h3_s: ['24px', '24px', '0px'],
  serifBody: ['36px', '42px', '0px'],
  serifBody_m: ['28px', '33px', '0px'],
  serifBody_s: ['22px', '26px', '0px'],
}

const details = {
  normal: {
    textTransform: 'none',
  },
  caps: {
    textTransform: 'uppercase',
  },
}

const variants = {
  body: ['bold', 'body', 'caps'],
  bodyMedium: ['bold', 'bodyLarge_s', 'caps'],
  bodyLarge: [
    'bold',
    ['bodyLarge_s', null, 'bodyLarge_m', null, 'bodyLarge'],
    'caps',
  ],
  h1: ['serif', ['h1_s', 'h2', null, 'h1_m', 'h1', null, null, 'h1_l'], 'caps'],
  h2: ['serif', ['h1_s', 'h2'], 'caps'],
  h2S: ['serif', ['h2_s', 'h2_m', null, 'h2'], 'caps'],
  h3: ['bold', ['h3_s', null, 'h3_m', null, 'h3'], 'caps'],
  serifBody: [
    'serifText',
    ['serifBody_s', null, 'serifBody_m', null, 'serifBody'],
    'normal',
  ],
}

export const typeData = buildTypeObject(styles, sizes)
export const typeVariants = buildTypeVariants(variants, styles, sizes, details)
