/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { atom, useAtom } from 'jotai'
import { AnimatePresence, motion } from 'framer-motion'

import { landingImageStageAtom, maxLandingImageStage } from '../landing-image'
import Link from '../link'
import Logo, { logoRatio } from '../logo'
import { setIsActiveAtom } from '../page-transition'

import usePageMap, { pathIsTemplate } from '../../lib/hooks/use-page-map'
import useScreenSize from '../../lib/hooks/use-screen-size'
import { fitDimensionsToBox } from '../../lib/math-helpers'

import {
  colorsFromColorMode,
  useValueForScreenWidth,
  valueFromTheme,
} from '../../styles/utils'

import Hamburger from './hamburger'
import Menu from './menu'

export const headerMenuActiveAtom = atom(false)

const calcLogoWidth = ({ isSmall, landingMult, padding }, screenSize) => {
  const baseWidth = 60

  if (isSmall) {
    return baseWidth
  }

  const maxWidth = Math.max(baseWidth, screenSize.width * landingMult - padding)
  const maxHeight = screenSize.height * 0.45 - padding

  const logoSize = fitDimensionsToBox(
    {
      width: maxWidth,
      height: maxHeight,
    },
    { ratio: logoRatio },
    true
  )

  return logoSize.width
}

const fadeVariants = {
  in: {
    opacity: 1,
    transition: {
      duration: 0.9,
    },
  },
  out: {
    opacity: 0,
  },
}

const Header = (props) => {
  const { path } = props
  const pageMap = usePageMap()
  const isHomepage = useMemo(() => {
    return pathIsTemplate(pageMap, path, 'Homepage')
  }, [pageMap, path])
  const screenSize = useScreenSize()

  const [headerMenuActive, setHeaderMenuActive] = useAtom(headerMenuActiveAtom)
  const [setIsActive] = useAtom(setIsActiveAtom)
  const [landingImageStage, setLandingImageStage] = useAtom(
    landingImageStageAtom
  )
  const { theme } = useThemeUI()

  const landingLogoMult = useValueForScreenWidth(
    [0.4, null, null, 0.225],
    theme,
    screenSize.width
  )
  const padding = valueFromTheme('space', 3, theme, { parse: true })

  const smallLogo = !isHomepage || landingImageStage > 1
  const logoSize = calcLogoWidth(
    {
      isSmall: smallLogo,
      landingMult: landingLogoMult,
      padding,
    },
    screenSize
  )
  const taglineVisible = isHomepage && landingImageStage < 2
  const burgerVisible = !isHomepage || landingImageStage >= maxLandingImageStage

  const isDark = !setIsActive
  const colors = colorsFromColorMode(isDark ? 'dark' : 'light', theme)
  const textColor = colors.text
  const bgColor = colors.background

  return (
    <React.Fragment>
      <Link
        to="/"
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 3,
          p: 3,
          pt: [2, null, null, 3],
        }}
        onClick={(e) => {
          if (isHomepage && landingImageStage < 2) {
            e.preventDefault()
            e.stopPropagation()
            setLandingImageStage(2)
          }
        }}
      >
        <motion.div
          initial={{ opacity: 0, width: logoSize }}
          animate={{
            width: logoSize,
            opacity: 1,
            transition: {
              type: 'tween',
              delay: 0.3,
              duration: smallLogo ? 0.9 : 0,
              ease: 'easeInOut',
            },
          }}
        >
          <Logo
            sx={{
              width: '100%',
            }}
            dark={isDark}
          />
        </motion.div>
      </Link>
      <motion.div
        key="hamburger"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          setHeaderMenuActive(!headerMenuActive)
        }}
        sx={{
          position: 'fixed',
          left: [null, null, null, 0],
          bottom: [null, null, null, 0],
          top: [0, null, null, 'auto'],
          right: [0, null, null, 'auto'],
          px: 3,
          pt: ['40px', null, null, 3],
          pb: 3,
          zIndex: 3,
          opacity: 0,

          '&:hover': {
            cursor: 'pointer',
          },
        }}
        style={{
          pointerEvents: !burgerVisible ? 'none' : null,
        }}
        variants={fadeVariants}
        animate={burgerVisible ? 'in' : 'out'}
      >
        <Hamburger open={headerMenuActive} color={textColor} />
      </motion.div>

      <Menu open={headerMenuActive} color={textColor} bgColor={bgColor} />
    </React.Fragment>
  )
}

export default Header
