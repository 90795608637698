import SetThumbnailLayer from './thumbnail'
import SetTitleLayer from './title'

const setLayerStyles = {
  position: 'fixed',
  left: 0,
  top: 0,
  pointerEvents: 'none',
  translate: 'translate3d(0,0,0)',
}

export { SetThumbnailLayer, SetTitleLayer, setLayerStyles }
