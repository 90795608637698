/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { get, isFinite } from 'lodash'
import { atom, useAtom } from 'jotai'
import { motion } from 'framer-motion'
import { css } from '@emotion/react'

import Image from '../image'
import Link from '../link'
import { setIsActiveAtom } from '../page-transition'
import Video from '../video'

import { formatImage } from '../../lib/helpers'
import formatLink from '../../lib/format-link'
import { properties } from '../../lib/animation'

import { hardwareAccelerate, scrollY } from '../../styles/utils'

import {
  hoveredSetIndexIdAtom,
  validSetId,
  getThumbnailField,
  getImageFromThumbnail,
  isPortrait,
} from './index'
import { navigate } from 'gatsby-link'

const SetThumbnail = (props) => {
  const { data, heightLandscape, heightPortrait, parentData, ...otherProps } =
    props
  const { databaseId } = data

  const touchStartPos = useRef([null, null])

  const [hoveredSetIndexId, setHoveredSetIndexId] = useAtom(
    hoveredSetIndexIdAtom
  )
  const [setIsActive] = useAtom(setIsActiveAtom)
  const thisSetIsHovered = hoveredSetIndexId === databaseId

  const thumbnail = getThumbnailField(data)
  const image = getImageFromThumbnail(thumbnail)

  const uri = parentData ? parentData.uri : data.uri
  const link = formatLink(uri)

  return (
    <Link
      to={link}
      sx={{
        display: 'block',
      }}
      onTouchStart={(e) => {
        if (!thisSetIsHovered) {
          setHoveredSetIndexId(null)
        }

        touchStartPos.current = [e.touches[0].clientX, e.touches[0].clientY]
      }}
      onTouchEnd={(e) => {
        const touchChange = [
          e.changedTouches[0].clientX - touchStartPos.current[0],
          e.changedTouches[0].clientY - touchStartPos.current[1],
        ]
        const touchDistance = Math.sqrt(
          Math.pow(touchChange[0], 2) + Math.pow(touchChange[1], 2)
        )

        if (touchDistance < 25) {
          if (!thisSetIsHovered) {
            e.preventDefault()
            e.stopPropagation()

            setHoveredSetIndexId(databaseId)
          } else {
            navigate(link)
          }
        }

        touchStartPos.current = [null, null]
      }}
    >
      <motion.div
        sx={{
          willChange: 'filter',
          display: 'block',
          position: 'relative',
          backgroundColor: 'darkGrey',
        }}
        onMouseOver={(e) => {
          if (!setIsActive) {
            setHoveredSetIndexId(databaseId)
          }
        }}
        onMouseOut={(e) => {
          setHoveredSetIndexId(null)
        }}
        css={css`
          ${hardwareAccelerate}
        `}
        variants={{
          default: {
            ...properties.noBlur,
            opacity: 1,
          },
          blurred: {
            ...properties.blur,
            ...properties.blurOpacity,
          },
        }}
        transition={{
          type: 'tween',
          duration: 0.4,
          ease: 'easeInOut',
        }}
        animate={
          validSetId(hoveredSetIndexId) && !thisSetIsHovered
            ? 'blurred'
            : 'default'
        }
        style={{
          height: !isPortrait(image) ? heightLandscape : heightPortrait,
        }}
        {...otherProps}
      >
        {thumbnail && thumbnail.image ? (
          <Image image={thumbnail.image} />
        ) : thumbnail &&
          (thumbnail.placeholder || thumbnail.vimeo || thumbnail.mux) ? (
          <Video
            placeholder={thumbnail.placeholder}
            vimeo={thumbnail.vimeo}
            mux={thumbnail.mux}
            tracksScroll={true}
            paused={setIsActive ? setIsActive : null}
          />
        ) : null}
      </motion.div>
    </Link>
  )
}

export default SetThumbnail
