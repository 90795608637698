/** @jsx jsx */
import React from 'react'
import { jsx, css as csx } from 'theme-ui'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

const HamburgerBar = motion(styled.div`
  width: 100%;
  height: 3px;
  ${csx({
    backgroundColor: 'text',
  })}
`)

const Hamburger = (props, ref) => {
  const { open, color } = props

  const barState = open ? 'open' : 'closed'
  const barTransition = {
    type: 'tween',
    duration: 0.5,
    ease: 'easeInOut',
  }

  const sx = {
    width: '30px',
    backgroundColor: color,
  }

  return (
    <React.Fragment>
      <HamburgerBar
        sx={{
          ...sx,
          mb: '8px',
        }}
        animate={barState}
        transition={barTransition}
        variants={{
          closed: {
            rotate: 0,
            translateY: 0,
          },
          open: {
            rotate: 45,
            translateY: 5,
          },
        }}
      />
      <HamburgerBar
        sx={{
          ...sx,
        }}
        animate={barState}
        transition={barTransition}
        variants={{
          closed: {
            rotate: 0,
            translateY: 0,
          },
          open: {
            rotate: -45,
            translateY: -6,
          },
        }}
      />
    </React.Fragment>
  )
}

export default Hamburger
