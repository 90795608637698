/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Helmet } from 'react-helmet'
import { get, find, isFinite } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { useAtom } from 'jotai'
import { motion, AnimatePresence } from 'framer-motion'
import { css } from '@emotion/react'

import { hoveredSetIndexIdAtom, validSetId } from '../set-index'
import { SetTitle } from '../set-title'

import { FullLayer } from '../../styles/els'
import { hardwareAccelerate } from '../../styles/utils'

import { setLayerStyles } from './index'

const MotionFullLayer = motion(FullLayer)

const TitleLayer = (props) => {
  const { ...otherProps } = props
  const [hoveredSetIndexId] = useAtom(hoveredSetIndexIdAtom)

  return (
    <AnimatePresence mode="wait">
      {validSetId(hoveredSetIndexId) ? (
        <MotionFullLayer
          key={hoveredSetIndexId}
          variants={{
            in: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
          initial="initial"
          animate="in"
          exit="out"
          sx={{
            ...setLayerStyles,
            zIndex: 2,
          }}
          {...otherProps}
        >
          <SetTitle
            css={css(`${hardwareAccelerate}`)}
            setId={hoveredSetIndexId}
          />
        </MotionFullLayer>
      ) : null}
    </AnimatePresence>
  )
}

export default TitleLayer
