/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'

const Link = (props) => {
  const { to, children, external, ...otherProps } = props

  return to ? (
    external ? (
      <a href={to} target="_blank" {...otherProps}>
        {children}
      </a>
    ) : (
      <GatsbyLink to={to} {...otherProps}>
        {children}
      </GatsbyLink>
    )
  ) : (
    <span {...otherProps}>{children}</span>
  )
}

export default Link
