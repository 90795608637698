/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'

import { InlineContainer } from '../image'

import Video from './index'

const InlineVideo = (props) => {
  const { placeholder, ratio, ...otherProps } = props

  return (
    <InlineContainer size={placeholder} ratio={ratio}>
      <Video placeholder={placeholder} {...otherProps} />
    </InlineContainer>
  )
}

export default InlineVideo
