/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useState, useEffect, useMemo } from 'react'
import { each, get, isFinite, min, indexOf, find, has } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import useDimensions from 'react-cool-dimensions'
import { atom, useSetAtom } from 'jotai'
import { Masonry } from 'masonic'
import { motion, useTransform } from 'framer-motion'

import useScreenSize from '../../lib/hooks/use-screen-size'
import { seoIm } from '../../lib/fragments'
import { formatImage, formatVimeo } from '../../lib/helpers'
import { roundTo } from '../../lib/math-helpers'

import { Cont, FlexCols, FlexCol } from '../../styles/els'
import {
  setValue,
  valueFromTheme,
  useThemeUISetValue,
  useThemeUISetValueForScreenWidth,
  useValueForScreenWidth,
} from '../../styles/utils'

import SetThumbnail from './thumbnail'

const allSetsQuery = graphql`
  query {
    sets: allWpSet(sort: { fields: menuOrder, order: ASC }) {
      nodes {
        databaseId
        title
        uri
        acf {
          client
          linksElsewhere
          otherLink {
            ... on WpSet {
              databaseId
            }
          }
          thumbnail {
            type
            image {
              ...ThumbnailImage
            }
            video {
              placeholder {
                ...ThumbnailImage
              }
              vimeoId
              mux
            }
          }
          images {
            ... on WpSet_Acf_Images_SetImage {
              fieldGroupName
              image {
                ...ThumbnailImage
              }
            }
            ... on WpSet_Acf_Images_SetVideo {
              fieldGroupName
              vimeo {
                placeholder {
                  ...ThumbnailImage
                }
                vimeoId
                mux
              }
            }
          }
        }
      }
    }
  }
`

export const useAllSets = () => {
  return useStaticQuery(allSetsQuery)
}

export const hoveredSetIndexIdAtom = atom(null)

export const validSetId = (index) => {
  return isFinite(index)
}

export const isPortrait = (image) => {
  const w = get(image, 'width')
  const h = get(image, 'height')

  return isFinite(w) && isFinite(h) && h > w
}

const prefixField = (fieldName, prefix = null) => {
  if (!prefix) {
    return fieldName
  } else if (!fieldName) {
    return null
  } else {
    return `${prefix}${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`
  }
}

export const getImageFromImageField = (image, prefix = null) => {
  const vimeoField = prefixField('vimeo', prefix)
  const imageFieldName = prefixField('image', prefix)

  return !image || image.fieldGroupName === 'set_Acf_Images_SetSpace'
    ? null
    : has(image, vimeoField)
    ? formatVimeo(get(image, vimeoField))
    : {
        image: formatImage(get(image, imageFieldName)),
      }
}

export const getThumbnailField = (node) => {
  let imageField = null
  const thumbnail = get(node, 'acf.thumbnail')

  if (thumbnail && thumbnail.type === 'image' && thumbnail.image) {
    imageField = {
      image: thumbnail.image,
    }
  } else if (
    thumbnail &&
    thumbnail.type === 'video' &&
    (thumbnail.video.vimeoId ||
      thumbnail.video.placeholder ||
      thumbnail.video.mux)
  ) {
    imageField = {
      vimeo: thumbnail.video,
    }
  } else {
    imageField = get(node, 'acf.images.0')
  }
  return getImageFromImageField(imageField)
}

export const getImageFromThumbnail = (thumbnail) => {
  return thumbnail
    ? thumbnail.placeholder
      ? thumbnail.placeholder
      : thumbnail.image
      ? thumbnail.image
      : null
    : null
}

export const getActiveSet = (id, sets) => {
  return find(sets && sets.nodes ? sets.nodes : [], { databaseId: id })
}

const calcHeights = ({ width, columns, padding, ratioPortrait }) => {
  const singularWidth = (width - padding * (columns - 1)) / columns
  const portraitHeight = roundTo(singularWidth * ratioPortrait, 10)
  const landscapeHeight = roundTo((portraitHeight - padding) / 2, 10)

  return [portraitHeight, landscapeHeight, width]
}

const SetThumbnailCard = (props) => {
  const {
    data: { data, parentData, heightPortrait, heightLandscape },
    index,
    width,
  } = props

  return (
    <SetThumbnail
      heightPortrait={heightPortrait}
      heightLandscape={heightLandscape}
      data={data}
      parentData={parentData}
    />
  )
}

const SetIndexContent = (props) => {
  const { screenSize } = props

  const data = useAllSets()
  const { observe, width } = useDimensions()

  const setHoveredSetIndexId = useSetAtom(hoveredSetIndexIdAtom)

  const { theme } = useThemeUI()

  const columnCount = useValueForScreenWidth(
    [1, null, 2],
    theme,
    screenSize.width
  )
  const padding = useThemeUISetValueForScreenWidth(
    'gridSpace',
    theme,
    screenSize.width
  )
  const pagePaddingTopValues = useThemeUISetValue('pagePT', theme)

  const [heightPortrait, heightLandscape, columnWidth] = useMemo(() => {
    const ratioPortrait = 1.25
    return calcHeights({
      width,
      columns: columnCount,
      padding,
      ratioPortrait,
    })
  }, [width, columnCount, padding])

  const nodes = get(data, 'sets.nodes')
  const columnData = useMemo(() => {
    return nodes.map((_n, _i) => {
      const _linksElsewhere = get(_n, 'acf.linksElsewhere')
      const _otherLink = _linksElsewhere
        ? get(_n, 'acf.otherLink.databaseId')
        : null
      const _otherNode =
        _linksElsewhere && _otherLink
          ? find(nodes, { databaseId: _otherLink })
          : null

      return {
        data: _n,
        parentData: _otherNode,
        heightLandscape,
        heightPortrait,
      }
    })
  }, [nodes, heightPortrait, heightLandscape])

  useEffect(() => {
    return () => {
      setHoveredSetIndexId(null)
    }
  }, [])

  return nodes && nodes.length > 0 ? (
    <Cont
      sx={{
        pt: pagePaddingTopValues,
      }}
      onTouchEnd={() => {
        setHoveredSetIndexId(null)
      }}
    >
      <div
        ref={observe}
        sx={{
          width: '100%',
        }}
      >
        <Masonry
          items={columnData}
          render={SetThumbnailCard}
          columnGutter={padding}
          columnWidth={columnWidth}
          columnCount={columnCount}
          itemKey={(data) => {
            return data.data.databaseId
          }}
        />
      </div>
    </Cont>
  ) : null
}

const SetIndex = (props) => {
  const { y: _y, landingImageDone } = props
  const screenSize = useScreenSize()

  const y = useTransform(
    _y,
    [0, 1],
    [!landingImageDone ? screenSize.fullHeight * 2 : 0, 0]
  )

  return (
    <motion.div style={{ y }}>
      <SetIndexContent screenSize={screenSize} />
    </motion.div>
  )
}

export { SetThumbnail }
export default SetIndex
