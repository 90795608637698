import colors, { colorData } from './colors'
import { typeData, typeVariants } from './type'
import { breakpointData } from './breakpoints'

import { mathOnCss, mixColor, themeTextBlockPPadding, pxToVW } from '../utils'

export default {
  // BREAKPOINTS
  breakpoints: [...breakpointData],

  // COLORS
  colors: {
    text: colorData.white,
    background: colorData.black,
    ...colorData,
    modes: {
      light: {
        text: colorData.black,
        background: colorData.white,
      },
      dark: {
        text: colorData.white,
        background: colorData.black,
      },
    },
  },

  // SPACES
  space: [0, 10, 20, 30, 60, 120],

  // Z
  zIndices: [0, 1, 50, 1000],

  // TYPOGRAPHY
  ...typeData,

  // VARIANTS
  text: {
    ...typeVariants,
  },

  textBlock: {
    body: {
      variant: 'text.body',

      a: {
        transition: 'opacity 0.4s ease-in-out',

        '&:hover': {
          opacity: 0.6,
          cursor: 'pointer',
        },
      },

      p: {
        ...themeTextBlockPPadding(typeData.lineHeights.body, (v) => v * 0.5),
      },
    },
    serifBody: {
      variant: 'text.serifBody',
      m: '0px auto',
      maxWidth: '1100px',

      p: {
        ...themeTextBlockPPadding(
          typeData.lineHeights.serifBody,
          (v) => v * 0.5
        ),
      },
    },
  },

  // SET VALUES
  setValues: {
    gridSpace: {
      property: 'space',
      value: [3, '45px', null, null, pxToVW(60, 1440), null, null, '75px'],
    },
    pagePT: {
      property: 'space',
      value: ['92px', null, null, 5],
    },
  },

  // STYLES
  styles: {
    root: {
      variant: 'text.body',
    },
  },

  useColorSchemeMediaQuery: false,
  useCustomProperties: false,
}
