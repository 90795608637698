/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useState, useEffect, useMemo } from 'react'
import { each, get, filter, findIndex } from 'lodash'
import { atom, useAtom, useSetAtom } from 'jotai'
import { graphql, useStaticQuery } from 'gatsby'
import { List } from 'masonic'

import { SetTitleLayer, SetThumbnailLayer } from '../set-layer'

import { Cont, FullLayer, Space } from '../../styles/els'
import { useThemeUISetValue, useValueForScreenWidth } from '../../styles/utils'

import useScreenSize from '../../lib/hooks/use-screen-size'

import Filters, { activeSetListFilterAtom, isValidFilter } from './filters'
import Item from './item'
import { AnimatePresence, motion } from 'framer-motion'

const allSetsQuery = graphql`
  query {
    sets: allWpSet(
      sort: { fields: title, order: ASC }
      filter: { acf: { linksElsewhere: { ne: true } } }
    ) {
      nodes {
        databaseId
        title
        uri
        acf {
          client
          thumbnail {
            type
            image {
              ...ThumbnailImage
            }
            video {
              placeholder {
                ...ThumbnailImage
              }
              vimeoId
              mux
            }
          }
          images {
            ... on WpSet_Acf_Images_SetImage {
              fieldGroupName
              image {
                ...ThumbnailImage
              }
            }
            ... on WpSet_Acf_Images_SetVideo {
              fieldGroupName
              vimeo {
                placeholder {
                  ...ThumbnailImage
                }
                vimeoId
                mux
              }
            }
          }
        }
        filters {
          nodes {
            name
            databaseId
          }
        }
      }
    }
  }
`

export const activeSetListIdAtom = atom(null)

const filterSets = (sets = [], filterId = null) => {
  if (!isValidFilter(filterId)) {
    return sets
  }

  return filter(sets, (o) => {
    return findIndex(get(o, 'filters.nodes'), { databaseId: filterId }) >= 0
  })
}

const SetListCard = (props) => {
  const { data } = props
  return <Item data={data} />
}

const SetList = (props) => {
  const setData = useStaticQuery(allSetsQuery)
  const sets = get(setData, 'sets.nodes')

  const [activeSetListFilter] = useAtom(activeSetListFilterAtom)
  const setActiveSetListId = useSetAtom(activeSetListIdAtom)

  const { theme } = useThemeUI()
  const pagePaddingTopValues = useThemeUISetValue('pagePT', theme)

  const screenSize = useScreenSize()

  const ignoreHovers = useValueForScreenWidth(
    [true, null, null, false],
    theme,
    screenSize.width
  )

  const filteredSets = useMemo(() => {
    return filterSets(sets, activeSetListFilter).map((_set, _i) => {
      return {
        ..._set,
        ignoreHovers,
      }
    })
  }, [sets, activeSetListFilter, ignoreHovers])

  return (
    <Cont
      sx={{ pt: pagePaddingTopValues, pb: 4 }}
      onTouchEnd={
        !ignoreHovers
          ? () => {
              setActiveSetListId(null)
            }
          : null
      }
    >
      <Space space={2} />
      <Filters />
      {filteredSets && filteredSets.length > 0 ? (
        <React.Fragment>
          <Space space={[4, null, null, 5]} />
          <AnimatePresence mode="wait">
            <motion.div
              key={`set-${
                activeSetListFilter ? activeSetListFilter : 'default'
              }`}
              variants={{
                in: {
                  opacity: 1,
                },
                out: {
                  opacity: 0,
                },
              }}
              initial="out"
              animate="in"
              exit="out"
            >
              {filteredSets.map((_s, _i) => {
                return <Item key={_i} data={_s} />
              })}
            </motion.div>
          </AnimatePresence>
        </React.Fragment>
      ) : null}
    </Cont>
  )
}

export default SetList
