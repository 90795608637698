/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useState, useEffect, useMemo } from 'react'
import { each, get, isFinite, min, indexOf } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { atom, useAtom } from 'jotai'

import Image from '../image'
import {
  useAllSets,
  getActiveSet,
  getThumbnailField,
  validSetId,
} from '../set-index'
import { activeSetListIdAtom } from '../set-list'
import Video from '../video'

import useScreenSize from '../../lib/hooks/use-screen-size'
import { formatImage } from '../../lib/helpers'

import { FullLayer } from '../../styles/els'

import { setLayerStyles } from './index'

const Thumbnail = (props) => {
  const { setId } = props
  const data = useAllSets()

  const activeSet = getActiveSet(setId, data.sets)
  const thumbnail = getThumbnailField(activeSet)

  return (
    <div
      sx={{
        position: 'absolute',
        left: ['12%', null, '25%'],
        right: ['12%', null, '25%'],
        top: ['5%', null, '15%'],
        bottom: ['5%', null, '15%'],
      }}
    >
      {thumbnail && thumbnail.image ? (
        <Image
          image={thumbnail.image}
          contain={true}
          backgroundColor="darkGrey"
        />
      ) : thumbnail && (thumbnail.placeholder || thumbnail.vimeo) ? (
        <Video
          placeholder={thumbnail.placeholder}
          vimeo={thumbnail.vimeo}
          mux={thumbnail.mux}
          contain={true}
          backgroundColor="darkGrey"
        />
      ) : null}
    </div>
  )
}

const ThumbnailLayer = (props) => {
  const { ...otherProps } = props
  const [activeSetListId] = useAtom(activeSetListIdAtom)
  const screenSize = useScreenSize()

  return validSetId(activeSetListId) ? (
    <FullLayer
      key={activeSetListId}
      sx={{
        ...setLayerStyles,
        zIndex: 0,
      }}
      style={{
        width: screenSize.width,
        height: screenSize.height,
      }}
      {...otherProps}
    >
      <Thumbnail setId={activeSetListId} />
    </FullLayer>
  ) : null
}

export default ThumbnailLayer
