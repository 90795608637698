import React from 'react'
import { get } from 'lodash'

import WrappedEl from './wrapped-el'

import { pathIsSet } from './src/components/page-transition'

export const wrapRootElement = ({ element }) => {
  return <WrappedEl>{element}</WrappedEl>
}

const transitionDelay = 300

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const location = get(routerProps, 'location')
  const prevLocation = get(prevRouterProps, 'location')

  if (!prevLocation) {
    return false
  }

  const involvesSet =
    (location && pathIsSet(window.__SETMAP__, location.pathname)) ||
    (prevLocation && pathIsSet(window.__SETMAP__, prevLocation.pathname))

  if (involvesSet) {
    return false
  }

  const savedPosition = getSavedScrollPosition(location)
  window.setTimeout(
    () => window.scrollTo(...(savedPosition || [0, 0])),
    transitionDelay
  )
  return false
}
