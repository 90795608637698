/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'
import { useAtom } from 'jotai'

import { headerMenuActiveAtom } from '../header'
import { setIsActiveAtom } from '../page-transition'
import SEO from '../seo'

import { properties } from '../../lib/animation'

const PageWrapper = (props) => {
  const { children, isSet, seo, ...otherProps } = props
  const [headerMenuActive] = useAtom(headerMenuActiveAtom)
  const [setIsActive] = useAtom(setIsActiveAtom)

  const f = false

  return (
    <motion.div
      key="pageWrapper"
      sx={{
        willChange: 'filter',
      }}
      variants={{
        default: {
          ...properties.noBlur,
        },
        blurred: {
          ...properties.blur,
        },
      }}
      transition={{
        type: 'tween',
        duration: 0.4,
        ease: 'easeInOut',
      }}
      animate={
        headerMenuActive || (!isSet && setIsActive) ? 'blurred' : 'default'
      }
      {...otherProps}
    >
      <SEO {...seo} />
      {children}
    </motion.div>
  )
}

export default PageWrapper
