/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import WebFont from 'webfontloader'
import { atom } from 'jotai'
import {  } from 'jotai/utils'

import Header from '../components/header'
import PageTransition from '../components/page-transition'
import PreventScroll from '../components/prevent-scroll'
import { SetTitleLayer, SetThumbnailLayer } from '../components/set-layer'
import { VideoAutoplayCheck } from '../components/video'

import Global from '../styles/global'

const Layout = (props) => {
  const { children, path, ...otherProps } = props
  return (
    <React.Fragment>
      <PreventScroll />
      {/*<VideoAutoplayCheck />*/}
      <Global />
      <Header path={path} />
      <SetTitleLayer />
      <SetThumbnailLayer />
      <PageTransition path={path} {...otherProps}>
        {children}
      </PageTransition>
    </React.Fragment>
  )
}

export default Layout
