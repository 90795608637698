/** @jsx jsx */
import { jsx, css as csx, useThemeUI } from 'theme-ui'
import React, { useRef, useEffect, useMemo } from 'react'
import { each, get, isFinite, min, indexOf } from 'lodash'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { atom, useAtom } from 'jotai'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'

import Link from '../link'
import { setIsActiveAtom } from '../page-transition'
import { validSetId } from '../set-index'

import formatLink from '../../lib/format-link'
import { properties } from '../../lib/animation'

import { setTORef, clearTORef } from '../../lib/hooks/use-timeout'

import { Cont, Space } from '../../styles/els'
import { hardwareAccelerate, mathOnValueFromTheme } from '../../styles/utils'

import { activeSetListIdAtom } from './index'
import Thumbnail from './thumbnail'

const spanPadding = ['4px', 1]

const Span = styled.span`
  transition: opacity 0.2s ease-in-out;

  ${csx({
    pr: spanPadding,
  })}
`

const PrimarySpan = styled(Span)()

const PrimaryDiv = styled.div`
  ${csx({
    variant: 'text.bodyLarge',
  })}
`

const SecondaryDiv = styled.div`
  padding-bottom: 4px;

  ${csx({
    variant: 'text.body',
  })}
`

const Item = (props) => {
  const { data } = props
  const { title, acf, uri, databaseId, filters, ignoreHovers } = data

  const isTouch = useRef(false)
  const isTouchTORef = useRef(null)
  const touchStartPos = useRef([null, null])

  const client = get(acf, 'client')
  const images = get(acf, 'images')
  const _filters = get(filters, 'nodes')

  const count = useMemo(() => {
    return images ? images.length : 0
  }, [images])
  const [setIsActive] = useAtom(setIsActiveAtom)
  const [activeSetListId, setActiveSetListId] = useAtom(activeSetListIdAtom)

  const aSetIsActive = validSetId(activeSetListId)
  const thisSetIsActive = activeSetListId === databaseId

  const activeState = aSetIsActive && thisSetIsActive

  const link = formatLink(uri)

  return (
    <Link
      to={link}
      sx={{
        position: 'relative',
        display: 'block',
      }}
      style={{
        zIndex: activeState ? 10 : 1,
      }}
      onTouchStart={
        !ignoreHovers
          ? (e) => {
              if (!thisSetIsActive) {
                setActiveSetListId(null)
              }

              touchStartPos.current = [
                e.touches[0].clientX,
                e.touches[0].clientY,
              ]
            }
          : null
      }
      onTouchEnd={
        !ignoreHovers
          ? (e) => {
              const touchChange = [
                e.changedTouches[0].clientX - touchStartPos.current[0],
                e.changedTouches[0].clientY - touchStartPos.current[1],
              ]
              const touchDistance = Math.sqrt(
                Math.pow(touchChange[0], 2) + Math.pow(touchChange[1], 2)
              )

              if (touchDistance < 25) {
                if (!thisSetIsActive) {
                  e.preventDefault()
                  e.stopPropagation()

                  setActiveSetListId(databaseId)
                } else {
                  navigate(link)
                }
              }

              touchStartPos.current = [null, null]
            }
          : null
      }
    >
      <div
        sx={{
          display: ['block', null, null, 'flex'],
          alignItems: [null, null, null, 'flex-end'],
          justifyContent: [null, null, null, 'flex-start'],
          pt: [1, null, null, '15px'],
          pb: [1, null, null, '15px'],
          mr: (theme) =>
            mathOnValueFromTheme('space', spanPadding, theme, (v) => v * -1, {
              fallbackUnit: 'px',
            }),

          [Span]: {
            opacity: !aSetIsActive ? 0.4 : thisSetIsActive ? 1 : 0.1,
          },

          [PrimarySpan]: {
            opacity: !aSetIsActive ? null : thisSetIsActive ? 1 : 0.2,
          },
        }}
        css={css`
          ${hardwareAccelerate}
        `}
        onTouchStart={
          !ignoreHovers
            ? () => {
                clearTORef(isTouchTORef)
                isTouch.current = true
              }
            : null
        }
        onTouchEnd={
          !ignoreHovers
            ? () => {
                clearTORef(isTouchTORef)
                setTORef(
                  () => {
                    isTouch.current = false
                  },
                  100,
                  isTouchTORef
                )
              }
            : null
        }
        onMouseEnter={
          !ignoreHovers
            ? (e) => {
                if (!setIsActive && !isTouch.current) {
                  setActiveSetListId(databaseId)
                }
              }
            : null
        }
        onMouseLeave={
          !ignoreHovers
            ? (e) => {
                setActiveSetListId(null)
              }
            : null
        }
      >
        {ignoreHovers ? <Thumbnail data={data} /> : null}
        <PrimaryDiv
          sx={{
            flex: 1,
          }}
        >
          {title ? (
            <PrimarySpan
              sx={{
                display: ['inline-block', 'auto'],
              }}
            >
              {title}
            </PrimarySpan>
          ) : null}
          {client ? <Span>{client}</Span> : null}
        </PrimaryDiv>
        <SecondaryDiv
          sx={{
            width: [
              '100%',
              null,
              null,
              `${(1 / 3) * 100}%`,
              `${(9 / 28) * 100}%`,
              null,
              `${(7 / 28) * 100}%`,
            ],
            textAlign: ['left', null, null, 'right', 'left'],
          }}
        >
          <Span>
            {_filters && _filters.length > 0
              ? _filters.map((_filter, _i) => {
                  return (
                    <React.Fragment key={_i}>
                      {_i > 0 ? <span>/</span> : null}
                      <span
                        sx={{
                          px: ['2px'],

                          '&:first-of-type': {
                            pl: 0,
                          },

                          '&:last-of-type': {
                            pr: 0,
                          },
                        }}
                      >
                        {_filter.name}
                      </span>
                    </React.Fragment>
                  )
                })
              : null}
          </Span>
        </SecondaryDiv>
        <SecondaryDiv
          sx={{
            width: `${(1 / 28) * 100}%`,
            textAlign: 'right',
            display: ['none', null, null, null, 'block'],
          }}
        >
          <Span>{count}</Span>
        </SecondaryDiv>
      </div>
    </Link>
  )
}

export default Item
