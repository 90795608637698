/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useAtom } from 'jotai'
import { Global } from '@emotion/react'

import { headerMenuActiveAtom } from '../header'
import { setIsActiveAtom } from '../page-transition'
import { landingImageStageAtom, maxLandingImageStage } from '../landing-image'

const PreventScroll = () => {
  const [setIsActive] = useAtom(setIsActiveAtom)
  const [headerMenuActive] = useAtom(headerMenuActiveAtom)
  const [landingImageStage] = useAtom(landingImageStageAtom)

  return setIsActive ||
    headerMenuActive ||
    landingImageStage < maxLandingImageStage ? (
    <Global
      styles={{
        body: {
          overflow: 'hidden !important',
        },
      }}
    />
  ) : null
}

export default PreventScroll
