/** @jsx jsx */
import { jsx, css as csx } from 'theme-ui'
import React from 'react'
import { Helmet } from 'react-helmet'
import { get, find, isFinite } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import styled from '@emotion/styled'

import { getActiveSet, useAllSets } from '../set-index'

const wrapperVariants = {
  in: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
  out: {
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.05,
    },
  },
}

const yDist = 25

const textVariants = {
  initial: {
    opacity: 0.01,
    y: '100%',
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  out: {
    opacity: 0.01,
    y: '-25%',
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: 'easeIn',
    },
  },
}

const useSetTitleData = (set) => {
  return !set ? [null, null] : [set.title, get(set, 'acf.client')]
}

const SetTitleWrapper = styled.div`
  ${csx({
    variant: 'text.h1',
    textAlign: 'center',
  })}
`

const SetTitle = (props) => {
  const { setId, ...otherProps } = props
  const data = useAllSets()

  const activeSet = getActiveSet(setId, data.sets)

  const [title, client] = useSetTitleData(activeSet)

  return (
    <SetTitleWrapper key={setId} {...otherProps}>
      {[title, client].map((_str, _i) => {
        const key = `${setId}_${_i}`
        return _str ? (
          <div
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
            key={key}
          >
            <motion.div key={`m-${key}`} variants={textVariants}>
              {_str}
            </motion.div>
          </div>
        ) : null
      })}
    </SetTitleWrapper>
  )
}

const SetTitleDumb = (props) => {
  const { set, ...otherProps } = props
  const [title, client] = useSetTitleData(set)
  return (
    <SetTitleWrapper {...otherProps}>
      {[title, client].map((_str, _i) => {
        return _str ? <div key={_i}>{_str}</div> : null
      })}
    </SetTitleWrapper>
  )
}

export { SetTitle, SetTitleDumb }
