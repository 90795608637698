/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { get, isFinite } from 'lodash'
import { atom, useAtom } from 'jotai'
import { motion } from 'framer-motion'
import { css } from '@emotion/react'

import Image, { RatioBox } from '../image'
import Link from '../link'
import { setIsActiveAtom } from '../page-transition'
import Video from '../video'

import { formatImage } from '../../lib/helpers'
import formatLink from '../../lib/format-link'
import { properties } from '../../lib/animation'

import { hardwareAccelerate, scrollY } from '../../styles/utils'

import { getThumbnailField, getImageFromThumbnail } from '../set-index'

const Thumbnail = (props) => {
  const { data } = props

  const thumbnail = getThumbnailField(data)

  const containProps = { contain: true, hAlign: 'left' }

  return (
    <RatioBox
      sx={{
        mb: 1,
      }}
      ratio={1 / 3}
    >
      {thumbnail && thumbnail.image ? (
        <Image
          image={thumbnail.image}
          backgroundColor="darkGrey"
          {...containProps}
        />
      ) : thumbnail &&
        (thumbnail.placeholder || thumbnail.vimeo || thumbnail.mux) ? (
        <Video
          placeholder={thumbnail.placeholder}
          vimeo={thumbnail.vimeo}
          mux={thumbnail.mux}
          tracksScroll={true}
          backgroundColor="darkGrey"
          {...containProps}
        />
      ) : null}
    </RatioBox>
  )
}

export default Thumbnail
