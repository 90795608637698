import { buildColorObject } from '../utils'

const colors = {
  black: [15, 15, 15],
  white: [255, 255, 255],
  grey: [110, 110, 110],
  darkGrey: [25, 25, 25],
  lightGrey: [245, 245, 245],
}

export default colors

export const colorData = buildColorObject(colors)
