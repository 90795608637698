import { getImage } from 'gatsby-plugin-image'
import { get } from 'lodash'
import htmr from 'htmr'

export const formatImage = (image) => {
  if (!image) {
    return null
  }

  const _image =
    get(image, 'localFile.childImageSharp.gatsbyImageData') ||
    get(image, 'childImageSharp.gatsbyImageData') ||
    get(image, 'gatsbyImageData') ||
    image

  const gatsbyImage = getImage(_image)

  if (gatsbyImage) {
    return gatsbyImage
  }

  const publicURL = get(image, 'localFile.publicURL') || get(image, 'publicURL')
  const size = get(image, 'mediaDetails')

  if (publicURL && size) {
    return {
      src: publicURL,
      width: size.width,
      height: size.height,
    }
  }

  return null
}

export const formatVimeo = (vimeo) => {
  if (!vimeo) {
    return null
  }

  return {
    placeholder: formatImage(get(vimeo, 'placeholder')),
    vimeo: get(vimeo, 'vimeoId'),
    mux: get(vimeo, 'mux'),
  }
}

export const renderHTML = (str) => {
  return htmr(str)
}
