/** @jsx jsx */
import React from 'react'
import { jsx, css as csx, Box } from 'theme-ui'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isFinite, isArray } from 'lodash'

import { valueFromTheme, percForProps, mathOnThemeValue, pxToVW } from './utils'

export const TextBlock = styled.div`
  ${csx({
    variant: 'textBlock.body',
  })};
`

export const Space = styled.div(({ space, theme }) => {
  const padding = valueFromTheme(
    'padding',
    isArray(space) || isFinite(space) ? space : 4,
    theme,
    { parse: true }
  )

  return csx({
    display: 'block',
    width: '100%',
    pb: padding,
  })
})

export const FullLayer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

export const Cont = styled.div`
  margin: 0px auto;
  ${csx({
    width: [
      '100%',
      null,
      null,
      null,
      `${(14 / 16) * 100}%`,
      null,
      null,
      null,
      `${(12 / 16) * 100}%`,
    ],
    px: [3, '90px', null, null, pxToVW(60, 1440), null, null, '75px'],
  })}
`

export const FlexCol = styled.div(({ theme, ...otherProps }) => {
  const width = percForProps(otherProps)

  return csx({
    position: 'relative',
    width,
  })
})

export const FlexColEmpty = styled(FlexCol)`
  display: block;
`

export const FlexCols = styled.div(({ space, theme }) => {
  const padding = valueFromTheme(
    'padding',
    isArray(space) || isFinite(space) ? space : 4,
    theme,
    { parse: true }
  )
  const p = mathOnThemeValue(padding, (_v) => _v, {
    fallbackUnit: 'px',
  })
  const hP = mathOnThemeValue(p, (_v) => _v * 0.5)
  const hPN = mathOnThemeValue(p, (_v) => _v * -0.5)

  return csx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    ml: hPN,
    mr: hPN,

    [`${FlexCol}, ${FlexColEmpty}`]: {
      pl: hP,
      pr: hP,
      pb: p,
    },
  })
})
