/** @jsx jsx */
import React from 'react'
import { jsx, css as csx, useThemeUI } from 'theme-ui'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'

import Link from '../link'

import { properties } from '../../lib/animation'
import formatLink from '../../lib/format-link'

import { mathOnValueFromTheme } from '../../styles/utils'
import { Cont, FullLayer } from '../../styles/els'

import { headerMenuActiveAtom } from './index'

const menuPageQuery = graphql`
  query {
    pages: allWpPage(filter: { uri: { ne: "/" } }) {
      nodes {
        id
        title
        uri
      }
    }
  }
`

const MotionFullLayer = motion(FullLayer)

const layerStyle = {
  position: 'fixed',
  left: 0,
  top: 0,
}

const variants = {
  in: {
    ...properties.blurOpacity,
  },
  out: {
    opacity: 0,
  },
}

const Menu = (props) => {
  const { open, color, bgColor, ...otherProps } = props
  const { theme } = useThemeUI()
  const data = useStaticQuery(menuPageQuery)
  const setHeaderMenuActive = useSetAtom(headerMenuActiveAtom)

  const pages = get(data, 'pages.nodes')

  return (
    <AnimatePresence>
      {open ? (
        <MotionFullLayer
          key="bg"
          sx={{
            ...layerStyle,
            zIndex: mathOnValueFromTheme('zIndex', 3, theme, (v) => v - 10),
            backgroundColor: bgColor ? bgColor : 'background',
          }}
          variants={variants}
          initial="out"
          animate="in"
          exit="out"
        />
      ) : null}
      {open ? (
        <MotionFullLayer
          key="menu"
          sx={{
            ...layerStyle,
            textAlign: 'center',
            zIndex: mathOnValueFromTheme('zIndex', 3, theme, (v) => v - 5),
            color: color ? color : 'text',
          }}
          variants={{
            in: {
              opacity: 1,
            },
            out: {
              opacity: 0,
            },
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setHeaderMenuActive(false)
          }}
          initial="out"
          animate="in"
          exit="out"
        >
          <Cont>
            <ul
              sx={{
                variant: 'text.h1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: ['stretch', null, null, 'flex-start'],
                flexDirection: ['column', null, null, 'row'],
                textAlign: 'center',

                a: {
                  px: [0, null, null, 1],
                  py: ['4px', null, null, 0],
                  display: 'block',
                },
              }}
            >
              {pages.map((_page, _i) => {
                const _link = formatLink(_page.uri)
                return (
                  <React.Fragment key={_i}>
                    {_i > 0 ? (
                      <li
                        sx={{
                          display: ['none', null, null, 'block'],
                        }}
                      >
                        /
                      </li>
                    ) : null}
                    <li>
                      <Link to={_link}>{_page.title}</Link>
                    </li>
                  </React.Fragment>
                )
              })}
            </ul>
          </Cont>
        </MotionFullLayer>
      ) : null}
    </AnimatePresence>
  )
}

export default Menu
