/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react'
import { jsx } from 'theme-ui'
import { isFinite, isFunction } from 'lodash'
import { motion } from 'framer-motion'
import formatDuration from 'format-duration'

import Icon from './icon'

const getTime = (videoRef) => {
  if (
    !videoRef ||
    !videoRef.current ||
    !isFinite(videoRef.current.currentTime)
  ) {
    return null
  }

  const currentTime = isFinite(videoRef.current.currentTime)
    ? videoRef.current.currentTime
    : 0
  const duration = isFinite(videoRef.current.duration)
    ? videoRef.current.duration
    : null
  const percentage =
    isFinite(currentTime) && isFinite(duration) ? currentTime / duration : 0

  return {
    currentTime,
    duration,
    percentage,
  }
}

const ControlIcon = (props) => {
  const { icon, ...otherProps } = props

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',

        '& > div': {
          width: '18px',
        },

        '&:hover': {
          cursor: 'pointer',
        },
      }}
      {...otherProps}
    >
      <Icon icon={icon} />
    </div>
  )
}

const TimeControlBar = (props) => {
  return (
    <div
      sx={{
        position: 'absolute',
        left: 0,
        top: '50%',
        mt: '-1px',
        height: '2px',
        backgroundColor: 'white',
      }}
      {...props}
    />
  )
}

const Controls = (props) => {
  const {
    videoRef,
    paused,
    muted,
    onSetTime,
    onTogglePlay,
    onToggleMuted,
    visible,
    ...otherProps
  } = props

  const [time, setTime] = useState(getTime(videoRef))

  const timeTrackRef = useRef(null)

  useEffect(() => {
    const onTimeUpdate = () => {
      setTime(getTime(videoRef))
    }
    const removeTimeListener = () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', onTimeUpdate)
      }
    }
    const addTimeListener = () => {
      removeTimeListener()
      if (videoRef && videoRef.current) {
        videoRef.current.addEventListener('timeupdate', onTimeUpdate)
      }
    }

    addTimeListener()
    return () => {
      removeTimeListener()
    }
  }, [videoRef])

  return (
    <motion.div
      sx={{
        variant: 'text.body',
        position: 'absolute',
        left: '14px',
        right: '14px',
        bottom: '12px',
        p: '5px 10px',
        zIndex: 3,
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.25)',
        backdropFilter: 'blur(10px)',
      }}
      variants={{
        in: {
          opacity: 1,
          y: '0%',
        },
        out: {
          opacity: 0,
          y: '100%',
        },
      }}
      transition={{
        duration: 0.5,
        easing: 'easeInOut',
      }}
      animate={visible ? 'in' : 'out'}
      {...otherProps}
    >
      <ControlIcon
        icon={paused ? 'play' : 'pause'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          onTogglePlay()
        }}
      />
      <div
        sx={{
          whiteSpace: 'nowrap',
          p: '9px 15px 0px 10px',
          flex: time ? null : 1,
        }}
      >
        {time
          ? `${formatDuration(time.currentTime * 1000)} / ${formatDuration(
              time.duration * 1000
            )}`
          : 'Loading'}
      </div>
      {time ? (
        <div
          sx={{
            position: 'relative',
            flex: 1,
            height: '30px',
            mr: '15px',

            '&:hover': {
              cursor: 'pointer',
            },
          }}
          ref={timeTrackRef}
          onClick={
            isFunction(onSetTime)
              ? (e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  const trackRect = timeTrackRef.current.getBoundingClientRect()
                  const xPos = e.pageX
                  const xPerc = (xPos - trackRect.x) / trackRect.width
                  const targetTime = xPerc * time.duration

                  onSetTime(targetTime)
                }
              : null
          }
        >
          <TimeControlBar
            sx={{ width: 0 }}
            style={{ width: `${time.percentage * 100}%` }}
          />
          <TimeControlBar
            sx={{
              width: '100%',
              opacity: 0.5,
            }}
          />
        </div>
      ) : null}
      <ControlIcon
        icon={muted ? 'mute' : 'volume'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          onToggleMuted()
        }}
      />
    </motion.div>
  )
}

export default Controls
