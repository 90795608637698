/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useState, useEffect, useMemo } from 'react'
import { each, get, isFinite, min, indexOf, filter, isNull } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { atom, useAtom } from 'jotai'
import { motion } from 'framer-motion'

import { Cont, Space } from '../../styles/els'

import Item from './item'

const allFiltersQuery = graphql`
  query {
    filters: allWpFilterType {
      nodes {
        databaseId
        name
      }
    }
  }
`

export const activeSetListFilterAtom = atom(null)

const variants = {
  active: {
    opacity: 1,
  },
  faded: {
    opacity: 0.2,
  },
}

export const isValidFilter = (id) => {
  return !isNull(id)
}

const Filters = (props) => {
  const data = useStaticQuery(allFiltersQuery)
  const filters = get(data, 'filters.nodes')
  const [activeSetListFilter, setActiveSetListFilter] = useAtom(
    activeSetListFilterAtom
  )
  const [hover, setHover] = useState(null)

  const hasActiveFilter =
    isValidFilter(hover) || isValidFilter(activeSetListFilter)

  return (
    <ul
      sx={{
        variant: 'text.h2S',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      {filters.map((_filter, _i) => {
        const _id = _filter.databaseId
        const _isActive = activeSetListFilter === _id
        return (
          <React.Fragment key={_i}>
            {_i > 0 ? <li>/</li> : null}
            <motion.li
              sx={{
                display: 'block',
                px: [0, null, null, 1],

                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onMouseEnter={(e) => {
                if (hover !== _id) {
                  setHover(_id)
                }
              }}
              onMouseLeave={(e) => {
                setHover(null)
              }}
              onClick={(e) => {
                setActiveSetListFilter(_isActive ? null : _id)
              }}
              animate={
                !hasActiveFilter || hover === _id || activeSetListFilter === _id
                  ? 'active'
                  : 'faded'
              }
              variants={variants}
            >
              {_filter.name}
            </motion.li>
          </React.Fragment>
        )
      })}
    </ul>
  )
}

export default Filters
