const formatLink = (slug, pageType = null) => {
  let link = '/'

  let path = pathForPageType(pageType)
  if (path) {
    link = addSlugToLink(path, link)
  }

  const slugParts = slug.split('#')
  link = addSlugToLink(slugParts[0], link)
  if (slugParts.length > 1) {
    link += '#' + slugParts[1]
  }

  return link
}

const addSlugToLink = (slug, link) => {
  if (slug.charAt(0) === '/') {
    slug = slug.slice(1)
  }

  link += slug

  if (link.slice(-1) !== '/') {
    link += '/'
  }

  return link
}

const pathForPageType = (pageType) => {
  switch (pageType) {
    default:
      return null
  }
}

export default formatLink
