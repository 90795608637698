import React from 'react'
import { Global, css } from '@emotion/react'

import reset from './reset'
import { fontFace } from './utils'

import DanzzaBoldEOT from './typeface/DanzzaBold.eot'
import DanzzaBoldSVG from './typeface/DanzzaBold.svg'
import DanzzaBoldWOFF from './typeface/DanzzaBold.woff'
import DanzzaBoldWOFF2 from './typeface/DanzzaBold.woff2'

import SaolDisplayEOT from './typeface/SaolDisplay-Regular.eot'
import SaolDisplayWOFF from './typeface/SaolDisplay-Regular.woff'
import SaolDisplayWOFF2 from './typeface/SaolDisplay-Regular.woff2'

import SaolTextEOT from './typeface/SaolText-Regular.eot'
import SaolTextWOFF from './typeface/SaolText-Regular.woff'
import SaolTextWOFF2 from './typeface/SaolText-Regular.woff2'

import IvarDisplayEOT from './typeface/IvarDisplay-Regular.eot'
import IvarDisplayWOFF from './typeface/IvarDisplay-Regular.woff'
import IvarDisplayWOFF2 from './typeface/IvarDisplay-Regular.woff2'

import IvarHeadlineEOT from './typeface/IvarHeadline-Regular.eot'
import IvarHeadlineWOFF from './typeface/IvarHeadline-Regular.woff'
import IvarHeadlineWOFF2 from './typeface/IvarHeadline-Regular.woff2'

export default (props) => {
  return (
    <Global
      styles={(theme) => css`
        ${reset}

        ${fontFace(
          `Danzza-Bold`,
          {
            eot: DanzzaBoldEOT,
            svg: DanzzaBoldSVG,
            woff: DanzzaBoldWOFF,
            woff2: DanzzaBoldWOFF2,
          },
          700,
          'normal'
        )}

        ${fontFace(
          `SaolDisplay-Regular`,
          {
            eot: SaolDisplayEOT,
            woff: SaolDisplayWOFF,
            woff2: SaolDisplayWOFF2,
          },
          400,
          'normal'
        )}

        ${fontFace(
          `SaolText-Regular`,
          {
            eot: SaolTextEOT,
            woff: SaolTextWOFF,
            woff2: SaolTextWOFF2,
          },
          400,
          'normal'
        )}

        ${fontFace(
          `IvarDisplay-Regular`,
          {
            eot: IvarDisplayEOT,
            woff: IvarDisplayWOFF,
            woff2: IvarDisplayWOFF2,
          },
          400,
          'normal'
        )}

        ${fontFace(
          `IvarHeadline-Regular`,
          {
            eot: IvarHeadlineEOT,
            woff: IvarHeadlineWOFF,
            woff2: IvarHeadlineWOFF2,
          },
          400,
          'normal'
        )}

        html,
        body {
          width: 100%;
        }

        body {
          min-height: 100%;
          overflow-x: hidden;
          overflow-y: visible;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: 'grayscale';

          &,
          * {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &:focus {
              outline: none;
            }
          }
        }

        a {
          color: inherit;
          text-decoration: none;

          &:focus {
            outline: none;
          }
        }
      `}
    />
  )
}
