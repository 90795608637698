/** @jsx jsx */
import React, { useMemo, useRef, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { get, find } from 'lodash'
import { atom, useSetAtom } from 'jotai'
import { motion, AnimatePresence } from 'framer-motion'

import { landingImageStageAtom, maxLandingImageStage } from '../landing-image'
import PageWrapper from '../page-wrapper'
import { activeSetListIdAtom } from '../set-list'
import { hoveredSetIndexIdAtom } from '../set-index'

import usePageMap, { pathIsTemplate } from '../../lib/hooks/use-page-map'
import useTimeout from '../../lib/hooks/use-timeout'

import { mathOnValueFromTheme, hardwareAccelerate } from '../../styles/utils'

const query = graphql`
  query {
    sets: allWpSet {
      nodes {
        uri
      }
    }
  }
`

export const pageLayerRouteAtom = atom(null)
export const isInitAtom = atom(true)
export const setIsActiveAtom = atom(false)

export const pathIsSet = (sets, path) => {
  return !!find(sets, { uri: path })
}

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.9,
      ease: 'easeInOut',
    },
  },
  out: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}

const setVariants = {
  initial: {
    opacity: 0,
    y: '100%',
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.9,
      ease: 'easeInOut',
    },
  },
  out: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}

const updatePrevProps = (props, prevPropsArray = null) => {
  const { path } = props
  const propsToAdd = { ...props }
  const prevProps = [...prevPropsArray]
  const firstPath = get(prevProps, '0.path')

  if (path !== firstPath) {
    prevProps.unshift(propsToAdd)
  } else {
    prevProps[0] = propsToAdd
  }

  return prevProps.slice(0, 2)
}

const PageTransition = (props) => {
  const { children, path } = props
  const prevPropsRef = useRef([])
  const pageMap = usePageMap()
  const data = useStaticQuery(query)
  const sets = useMemo(() => {
    return get(data, 'sets.nodes')
  }, [data])
  const [isSet, prevProps] = useMemo(() => {
    const isSet = pathIsSet(sets, path)
    prevPropsRef.current = updatePrevProps(props, prevPropsRef.current)
    return [isSet, get(prevPropsRef, 'current.1') || null]
  }, [path, sets])

  const setIsInitAtom = useSetAtom(isInitAtom)
  const setHoveredSetIndexId = useSetAtom(hoveredSetIndexIdAtom)
  const setActiveSetListId = useSetAtom(activeSetListIdAtom)
  const setSetIsActive = useSetAtom(setIsActiveAtom)
  const setPageLayerRoute = useSetAtom(pageLayerRouteAtom)
  const setLandingImageStage = useSetAtom(landingImageStageAtom)
  const { theme } = useThemeUI()

  const pageElement = !isSet
    ? children
    : prevProps
      ? React.createElement(prevProps.pageResources.component.default, {
          ...prevProps,
          key: prevProps.pageResources.page.path,
        })
      : null

  const pagePath = !isSet
    ? path
    : prevProps && pageElement
      ? prevProps.path
      : null

  const setElement = isSet ? children : null
  const setPath = isSet && setElement ? path : null

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.__SETMAP__ = sets
    }
    if (!pathIsTemplate(pageMap, path, 'Homepage')) {
      setLandingImageStage(maxLandingImageStage)
    }
  }, [])

  useEffect(() => {
    setSetIsActive(isSet)
    setHoveredSetIndexId(null)
    setActiveSetListId(null)
    setPageLayerRoute(prevProps && prevProps.path ? prevProps.path : null)
  }, [path])

  useTimeout(() => {
    setIsInitAtom(false)
  }, 100)

  return (
    <React.Fragment>
      <AnimatePresence mode="wait">
        {pageElement ? (
          <motion.div
            key={pagePath}
            sx={{ position: 'relative', zIndex: 1 }}
            variants={pageVariants}
            initial="initial"
            animate="in"
            exit="out"
          >
            {pageElement}
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {setElement ? (
          <motion.div
            key={setPath}
            sx={{
              position: 'fixed',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: mathOnValueFromTheme('zIndex', 2, theme, (v) => v + 10),
            }}
            css={css`
              ${hardwareAccelerate}
            `}
            variants={setVariants}
            initial={pageElement ? 'initial' : null}
            animate="in"
            exit="out"
          >
            {setElement}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default PageTransition
