import React, { useRef, useEffect, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { each, get } from 'lodash'

const query = graphql`
  query {
    pages: allWpPage {
      nodes {
        uri
        template {
          templateName
        }
      }
    }
  }
`

export const pathIsTemplate = (pageMap = {}, path = '', template = '') => {
  return (
    pageMap && pageMap[path] && get(pageMap, `${path}.template`) === template
  )
}

const usePageMap = () => {
  const data = useStaticQuery(query)
  const p = useMemo(() => {
    const pages = get(data, 'pages.nodes')
    const _p = {}
    each(pages, (_page, i) => {
      _p[`${_page.uri}`] = {
        template: _page.template.templateName,
      }
    })
    return _p
  }, [data])
  return p
}

export default usePageMap
