/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { isFinite } from 'lodash'

import { calcRatio, roundTo } from '../../lib/math-helpers'

import Image from './fill'

export const RatioBox = (props) => {
  const { children, ratio, backgroundColor, ...otherProps } = props

  const _ratio = isFinite(ratio) ? ratio : 1

  return (
    <div
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        backgroundColor,

        '&:before': {
          content: '""',
          display: 'block',
          width: '100%',
          pb: `${roundTo(_ratio * 100, 10000)}%`,
        },
      }}
      {...otherProps}
    >
      {children}
    </div>
  )
}

export const InlineContainer = (props) => {
  const { children, size, ratio, ...otherProps } = props

  const _ratio = calcRatio(size, ratio)

  return (
    <RatioBox ratio={_ratio} {...otherProps}>
      {children}
    </RatioBox>
  )
}

const ImageInline = (props) => {
  const {
    image,
    ratio,
    contain,
    onImageLoad,
    gatsbyImageProps,
    imageProps,
    ...otherProps
  } = props

  return (
    <InlineContainer size={image} ratio={ratio} {...otherProps}>
      {image ? (
        <Image
          image={image}
          sx={{ zIndex: 1 }}
          contain={contain}
          onImageLoad={onImageLoad}
          gatsbyImageProps={gatsbyImageProps}
          {...imageProps}
        />
      ) : null}
    </InlineContainer>
  )
}

export default ImageInline
