/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import { RatioBox } from '../image'

import { fillArea, floodBgImage } from '../../styles/utils'

import LogoImgWhite from './logo-white.svg'
import LogoImgBlack from './logo-black.svg'

export const logoRatio = 260 / 300

const Logo = (props) => {
  const { sx, dark, ...otherProps } = props

  return (
    <RatioBox ratio={logoRatio} {...otherProps}>
      <div
        sx={{
          backgroundImage: `url(${dark ? LogoImgWhite : LogoImgBlack})`,
        }}
        css={css`
          ${fillArea};
          ${floodBgImage};
        `}
      />
    </RatioBox>
  )
}

export default Logo
