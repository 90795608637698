export const properties = {
  blur: {
    filter: `blur(10px)`,
  },
  blurMore: {
    filter: `blur(20px)`,
  },
  noBlur: {
    filter: `blur(0px)`,
  },
  blurOpacity: {
    opacity: 0.5,
  },
}
