/** @jsx jsx */
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { get, jsx } from 'theme-ui'
import { isFinite, isFunction, isUndefined } from 'lodash'
import { atom, useAtom } from 'jotai'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import { css } from '@emotion/react'

import Image from '../image'
import { getImageFromImageField } from '../set-index'
import Video from '../video'

import { properties } from '../../lib/animation'
import { setScrollTopPosition } from '../../lib/layout-helpers'
import { formatVimeo } from '../../lib/helpers'
import useScreenSize from '../../lib/hooks/use-screen-size'
import { clearTORef, setTORef } from '../../lib/hooks/use-timeout'

import { Cont } from '../../styles/els'
import { fillArea, hardwareAccelerate, pxToVW } from '../../styles/utils'

export const landingImageStageAtom = atom(0)
export const maxLandingImageStage = 3

const getLandingStageTime = (stage) => {
  let nextTime = 1200

  switch (stage) {
    case 1:
      nextTime = 2400
      break
    case 2:
      nextTime = 8000
      break
    default:
      nextTime = 1200
  }

  return nextTime
}

const LandingSection = (props) => {
  const { children, ...otherProps } = props
  const screenSize = useScreenSize()

  return (
    <div
      sx={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
      style={{
        width: screenSize.width,
        height: screenSize.fullHeight,
      }}
      {...otherProps}
    >
      {children}
    </div>
  )
}

const LandingImage = (props) => {
  const { images, y } = props
  const [index] = useState(
    typeof window === 'undefined'
      ? null
      : images && images.length > 0
      ? Math.floor(Math.random() * images.length)
      : 0
  )
  const image = useMemo(() => {
    const _image =
      isFinite(index) && index < images.length ? images[index] : null
    return _image &&
      _image.fieldGroupName === 'page_Acftemplatehomepage_Images_HomeVideo'
      ? {
          video: formatVimeo(_image.video),
          portraitVideo: formatVimeo(_image.portraitVideo),
        }
      : _image &&
        _image.fieldGroupName === 'WpPage_Acftemplatehomepage_Images_HomeImage'
      ? getImageFromImageField(_image)
      : null
  }, [index, images])
  const screenSize = useScreenSize()
  const isPortrait = useMemo(() => {
    const portraitRatio = 1
    return screenSize.width <= screenSize.height * portraitRatio
  }, [screenSize.width, screenSize.height])
  const [landingImageStage, setLandingImageStage] = useAtom(
    landingImageStageAtom
  )
  const landingImageTO = useRef({})

  const yOffset = useTransform(y, [0, 1], [0, screenSize.fullHeight * -2])
  const taglineOffset = useTransform(
    y,
    [0, 1],
    [screenSize.fullHeight * -0.45, screenSize.fullHeight * 0.45]
  )

  useEffect(() => {
    if (landingImageStage < 2) {
      const nextStage = landingImageStage + 1
      setTORef(
        () => {
          setLandingImageStage(nextStage)
        },
        getLandingStageTime(nextStage),
        landingImageTO
      )
    }
    if (landingImageStage === 2) {
      animate(y, 1, {
        duration: 2.0,
        type: 'tween',
        ease: 'easeInOut',
        onComplete: () => {
          setLandingImageStage(maxLandingImageStage)
        },
      })
    }

    return () => {
      clearTORef(landingImageTO)
    }
  }, [landingImageStage])

  return (
    <motion.div
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 10,
        backgroundColor: 'background',
      }}
      style={{
        y: yOffset,
      }}
    >
      <LandingSection
        key="image"
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setLandingImageStage(2)
        }}
      >
        <motion.div
          sx={{
            willChange: 'filter',
          }}
          css={css`
            ${fillArea};
            ${hardwareAccelerate};
          `}
          initial={false}
          animate={landingImageStage > 0 ? 'default' : 'blurred'}
          variants={{
            default: {
              ...properties.noBlur,
            },
            blurred: {
              ...properties.blurMore,
            },
          }}
          transition={{
            type: 'tween',
            duration: 0.8,
            ease: 'easeInOut',
          }}
        >
          {landingImageStage < maxLandingImageStage ? (
            image && image.image ? (
              <Image image={image.image} />
            ) : isPortrait &&
              image &&
              image.portraitVideo &&
              (image.portraitVideo.placeholder || image.portraitVideo.mux) ? (
              <Video
                key={`v-portrait-${index}`}
                placeholder={image.portraitVideo.placeholder}
                mux={image.portraitVideo.mux}
              />
            ) : image &&
              image.video &&
              (image.video.placeholder || image.video.mux) ? (
              <Video
                key={`v-${index}`}
                placeholder={image.video.placeholder}
                mux={image.video.mux}
              />
            ) : null
          ) : null}
        </motion.div>
      </LandingSection>
      <LandingSection
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        key="name"
      >
        <Cont>
          <motion.div
            sx={{ variant: 'text.h3', textAlign: 'center' }}
            style={{ y: taglineOffset }}
          >
            Director & photographer
          </motion.div>
        </Cont>
      </LandingSection>
    </motion.div>
  )
}

export default LandingImage
